export const playPauseAnimation = [
  {
    opacity: 1,
    transform: "translate(-50%, -50%) scale(1)",
  },

  {
    opacity: 0,
    transform: "translate(-50%, -50%) scale(1.3)",
  },
];
